<template>
  <WsMain>
    <WsCrud
      :modelName="$store.state.stone_model[modelName].modelName"
      :label="$store.state.stone_model[modelName].label"
      :fields="$store.state.stone_model[modelName].fields"
      :liveSearching="true"
      :pageMode="true"
      :showFields="_showFields"
      :expandable="false"
      :showExpand="false"
      :infiniteScroll="true"
      :inRowBtnRead="false"
      :labelInLocale="true"
    ></WsCrud>
  </WsMain>
</template>

<script>
export default {
  metaInfo() {
    return {
      title: this.$store.state.stone_model[this.modelName].label,
    };
  },

  data: () => ({
    modelName: "service_store",
  }),

  computed: {
    _showFields() {
      const _showFields = ["id", "name", "address"];
      if (this.$config.wsmodule.area) {
        _showFields.push("area");
      }
      if (this.$config.wsmodule.country_code) {
        _showFields.push("country_code");
      }
      return _showFields;
    },
  },
};
</script>